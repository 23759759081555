import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import SignatureCanvas from "react-signature-canvas";
import "./PreviewTerms.scss";
import { ASSETS_URL } from "src/config";

const AcceptTerms = () => {
  const signatureCanvasRef = useRef(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const enableSubmit = () => setSubmitEnabled(true);
  const navigate = useNavigate();

  const clientName = "PREVIEW";
  const userId = document?.userId;
  const cacheKiller = new Date().toISOString();
  const terms = document?.termsContent || "";
  const theme = document?.theme;

  useEffect(() => {
    document.querySelector("body")?.style?.setProperty("--primary", theme);
    document.querySelector("body")?.style?.setProperty("--primary-color", theme);
  }, [theme]);

  const clearSignature = () => {
    signatureCanvasRef?.current?.clear();
    setSubmitEnabled(false);
  };

  const submit = async () => {
    return;
  };

  let termsContent;

  try {
    termsContent = JSON.parse(terms).map(t => {
      if (t.type === "header") {
        return <p className="section-title">{t.value}</p>;
      } else {
        return <p className="section-text">{t.value}</p>;
      }
    });
  } catch {}

  // {
  //   terms.map(t => {
  //     if (t.type === "header") {
  //       return <p className="section-title">{t.value}</p>;
  //     } else {
  //       return <p className="section-text">{t.value}</p>;
  //     }
  //   });
  // }

  return (
    <div className="terms-root-container">
      <div className="content">
        <div className="header">
          <img className="logo" src={`${ASSETS_URL}/${userId}/logo.png?v=${cacheKiller}`} alt="logo" />
          <p className="text">Terms of Service</p>
        </div>
        <div className="terms">{termsContent}</div>
      </div>
      <div className="signature-bar">
        <SignatureCanvas
          penColor="black"
          ref={signatureCanvasRef}
          onBegin={enableSubmit}
          clearOnResize={false}
          canvasProps={{ className: "canvas" }}
        />
        <div className="separator-bar" />
        <p className="subtitle">Add your signature above</p>
        <div className="buttons">
          <div className="button reset" onClick={clearSignature}>
            CLEAR
          </div>
          <div className={`button accept${submitEnabled ? "" : " disabled"}`} onClick={submit}>
            ACCEPT TERMS
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AcceptTerms);
