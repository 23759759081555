import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import ClientDataStore from "src/stores/ClientDataStore";
import "./AcceptTerms.scss";
import { API_HOST, ASSETS_URL, WEB_APP_URL } from "src/config";

const AcceptTerms = () => {
  const signatureCanvasRef = useRef(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const enableSubmit = () => setSubmitEnabled(true);
  const navigate = useNavigate();

  const { code } = useParams() || "";

  let [userId, setUserId] = useState("");
  let [id, setId] = useState("");
  let [clientName, setClientName] = useState("");
  let [preview, setPreview] = useState("");
  let [terms, setTerms] = useState([]);
  let [cacheKiller, setCacheKiller] = useState("1");
  let [color, setColor] = useState("");

  useEffect(() => {
    if (code.length > 6 && code.split("-").length === 3) {
      const theme = "#" + code.split("-")[1];
      const userId = code.split("-")[2];
      setUserId(userId);
      setCacheKiller(new Date().valueOf().toString());
      document.querySelector("body")?.style?.setProperty("--primary", theme);
      setPreview(true);
      return;
    }

    (async () => {
      try {
        ClientDataStore.setLoading(true);
        const { data } = await axios.get(`${API_HOST}/v1/terms/code?code=${code}`);
        const { userId, clientId, theme, terms, clientName } = data;
        if (!userId) window.location.replace(WEB_APP_URL);
        setUserId(userId);
        setId(clientId);
        setTerms(terms);
        setColor(theme);
        document.querySelector("body")?.style?.setProperty("--primary", theme);
        document.querySelector("body")?.style?.setProperty("--primary-color", theme);
        setClientName(clientName);
        ClientDataStore.setLoading(false);
      } catch (err) {
        console.warn(err);
      }
    })();
  }, [code]);

  const clearSignature = () => {
    signatureCanvasRef?.current?.clear();
    setSubmitEnabled(false);
  };

  const dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  const getURLForUpload = async () => {
    try {
      const { data } = await axios.get(`${API_HOST}/v1/terms/upload?id=${id}&userId=${userId}`);
      return data;
    } catch (e) {
      console.warn(e);
    }
  };

  const submit = async () => {
    if (preview) return;

    if (submitEnabled) {
      ClientDataStore.setLoading(true);
      const dataUrl = signatureCanvasRef?.current?.getCanvas().toDataURL(`image/png`);
      //get upload url

      const { uploadURL, assetURL } = await getURLForUpload();
      let blobData = dataURItoBlob(dataUrl);
      ClientDataStore.setId(id);
      ClientDataStore.setUserId(userId);
      ClientDataStore.setClientName(clientName);

      await axios.put(uploadURL, blobData, { headers: { "Content-Type": blobData["type"] || "image/png" } });
      const PDFUrl = await axios.post(`${API_HOST}/v1/terms/signature`, {
        url: assetURL,
        id,
        userId,
        clientName,
        color,
        terms
      });
      await axios.delete(`${API_HOST}/v1/terms/code?code=${code}`);
      ClientDataStore.setAssetURL(PDFUrl.data);
      ClientDataStore.setLoading(false);
      navigate("/thankyou");
    }
  };

  return (
    <div className="terms-root-container">
      <div className="content">
        <div className="header">
          <img className="logo" src={`${ASSETS_URL}/${userId}/logo.png?v=${cacheKiller}`} alt="logo" />
          <p className="text">Terms of Service</p>
        </div>
        <div className="terms">
          {terms.map(t => {
            if (t.type === "header") {
              return <p className="section-title">{t.value}</p>;
            } else {
              return <p className="section-text">{t.value}</p>;
            }
          })}
        </div>
      </div>
      <div className="signature-bar">
        <SignatureCanvas
          penColor="black"
          ref={signatureCanvasRef}
          onBegin={enableSubmit}
          clearOnResize={false}
          canvasProps={{ className: "canvas" }}
        />
        <div className="separator-bar" />
        <p className="subtitle">Add your signature above</p>
        <div className="buttons">
          <div className="button reset" onClick={clearSignature}>
            CLEAR
          </div>
          <div className={`button accept${submitEnabled ? "" : " disabled"}`} onClick={submit}>
            ACCEPT TERMS
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AcceptTerms);
