import React from "react";
// import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useModal } from "frontend-shared/hooks";
// import OnboardingModalBody from "src/components/OnboardingModal";
import "./App.scss";
import AcceptTerms from "src/components/@AcceptTerms";
import Redirect from "src/components/Redirect";
import ThankYou from "src/components/@ThankYou";
import { PuffLoader } from "react-spinners";
import ClientDataStore from "src/stores/ClientDataStore";
import PreviewTerms from "src/components/@PreviewTerms";

// if (process.env.REACT_APP_STAGE !== "prod") labelFaviconWithStage(process.env.REACT_APP_STAGE);

const Main = observer(() => {
  const loading = ClientDataStore.loading;
  const loader = loading ? (
    <div className="loader-container">
      <PuffLoader loading className="loader" size={80} />
    </div>
  ) : null;

  return (
    <div className="main-content">
      <Routes>
        <Route element={<ThankYou />} path="/thankyou/" />
        <Route element={<AcceptTerms />} path="/sign/:code" />
        <Route element={<PreviewTerms />} path="/preview" />
        {/* <Route element={< />} path="/sign/:data" /> */}
        <Route element={<Redirect to="/thankyou" />} path="*" />
      </Routes>
      {loader}
    </div>
  );
});

const App = () => {
  return <Main />;
};

function ProvidersWrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default ProvidersWrappedApp;
