import { makeAutoObservable } from "mobx";

class ClientDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  userId = "";
  id = "";
  assetURL = "";
  clientName = "";
  code = "";

  setLoading(loading) {
    this.loading = loading;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setId(id) {
    this.id = id;
  }

  setAssetURL(assetURL) {
    this.assetURL = assetURL;
  }

  setClientName(clientName) {
    this.clientName = clientName;
  }

  setCode(code) {
    this.code = code;
  }
}

export default new ClientDataStore();
