import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Input from "../Input";
import Button from "../Button";
import logo from "src/assets/H_S_Horiz.png";
import axios from "axios";
import emailValidator from "src/utils/emailValidator";
import ClientDataStore from "src/stores/ClientDataStore";
import "./ThankYou.scss";
import { API_HOST } from "src/config";

const AcceptTerms = () => {
  let { assetURL, userId, id } = ClientDataStore || {};

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const onEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const emailValid = email.match(emailValidator);

  // useEffect(() => {
  //   window.addEventListener("message", m => alert(m.data));
  // }, []);

  const submitEmail = async () => {
    if (!emailValid) return;
    setSubmitted(true);

    await axios.post(`${API_HOST}/v1/terms/email`, {
      url: assetURL,
      id,
      userId,
      email
    });
  };

  const test = document?.termsContent ? <p className="text">CHICKENS!</p> : null;

  return (
    <div className="terms-root-container">
      <div className="content">
        <div className="header">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="thankyou">
          <p className="title">Thank you!</p>
          {test}
          <p className="text">We have successfully recorded your signature.</p>
          <p className="text">If you would like a copy of our agreement, and email reminders about appointments, enter your email below.</p>
          {!submitted && (
            <>
              <Input
                value={email}
                type={"email"}
                error={!emailValid && email ? "Invalid email" : null}
                onChange={onEmailChange}
                placeholder={"johndoe@email.com"}
              />
              <Button className="button" action={submitEmail} disabled={!emailValid}>
                submit
              </Button>
            </>
          )}
          {submitted && <div>An email will be sent to {email} shortly!</div>}
        </div>
      </div>
    </div>
  );
};

export default observer(AcceptTerms);
